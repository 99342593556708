import React from 'react'
import './Tutorial.css'
import { DemoButton } from '../Button';

export default function Tutorial()
{
    return (
        <div className="tutorial-container">
            <h1>How To Use ASL-Live</h1>
            <h2>Tutorial Video</h2>
            <h3>See it in action!</h3>
            <iframe
                src="https://www.youtube.com/embed/-9Ado8D3A-w"
                title="Tutorial Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="tutorial-video"
            ></iframe>
            <h4>Click Play above to view a short tutorial on how to use our product.</h4>
            <DemoButton buttonStyle="btn--primary" buttonSize="btn--large">
                Try It Out!
            </DemoButton>
        </div>
    );
}