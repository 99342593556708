import React from 'react'
import './SignUp.css' // Ensure the CSS import is correct

export default function SignUp() {
    return (
        <div className="signup-container">
            <h1 className="signup-title">With an ASL-Live Account, you can contribute <br />to our ever growing dataset, and make our machine learning model more accurate!</h1>
            <input type="text" placeholder="Email" className="signup-input" />
            <input type="password" placeholder="Password" className="signup-input" />
            <div className="signup-buttons">
                <button className="signup-btn">Login</button>
                <button className="signup-btn">Register</button>
            </div>
            <a href="#" className="forgot-password-link">Forgot Your Password?</a>
        </div>
    );
}