import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LoginButton } from './Button';
import './Navbar.css';
import { RiMoonClearLine } from "react-icons/ri";
import { TbHandTwoFingers } from "react-icons/tb";

function Navbar()
{
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [isDarkMode, setIsDarkMode] = useState(false); // State to track theme
    const toggleTheme = () =>
    {
        setIsDarkMode(!isDarkMode); // Toggle theme state
    };

    const showButton = () =>
    {
        if (window.innerWidth <= 960)
        {
            setButton(false);
        }
        else
        {
            setButton(true);
        }
    };

    useEffect(() =>
    {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className={`navbar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        ASL-Live <TbHandTwoFingers className="navbar-icon"/>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? "fa-solid fa-times" : "fa-solid fa-bars"} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className='nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="/about" className='nav-links' onClick={closeMobileMenu}>
                                About Us
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="/" className='nav-links' onClick={toggleTheme}>
                                <RiMoonClearLine />
                            </Link>
                        </li>
                    </ul>
                    {button && <LoginButton buttonStyle='btn--outline'>LOGIN/SIGN-UP</LoginButton>}
                </div>
            </nav>
        </>
    );
}

export default Navbar;
