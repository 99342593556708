import React from 'react';
import './Reviews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
// Correctly import modules from their specific paths
import { Pagination, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const testimonialsData = [
  {
    avatar: "/images/renato.jpg",
    name: 'Kanye West',
    review: 'This application inspired me to make Runaway! I hate the Jews!'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Taylor Swift',
    review: 'My favorite dish is literally unseasoned chicken.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Scott Mescudi',
    review: 'Mmmmmmmhmhmhmhhmmhmhmhmh marijuana.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'O.J. Simpson',
    review: 'I did not kill my wife. I swear on my mother\'s grave.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Peter Griffin',
    review: 'I love my wife Lois, but I also love my daughter Meg.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Osama Bin Laden',
    review: 'I did 9/11. I am not sorry.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Rick Leinecker',
    review: 'This is my favorite project ever! I love my students!'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Arup Guha',
    review: 'I only teach intelligent people. I am the best professor at UCF.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Will Smith',
    review: 'I love my wife Jada, but she don\'t love me.'
  },
  {
    avatar: "/images/renato.jpg",
    name: 'Helen Keller',
    review: '...'
  },
];

function Reviews() {
  return (
    <section id='testimonials'>
      <h2>Reviews From Our Esteemed Supporters & Contributors</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination, A11y]}
        spaceBetween={100}
        slidesPerView={3}
        pagination={{ clickable: true }}>
        {
          testimonialsData.map(({ avatar, name, review }, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} alt={name} />
                </div>
                <h3 className='client__name'>{name}</h3>
                <small className='client__review'>
                  {review}
                </small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  );
}

export default Reviews;