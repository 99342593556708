// import React, { useState } from 'react';
// import './Support.css';
// import Contact from '../Contact';
// import { FaSquareGithub } from "react-icons/fa6";

// const faqData = [
//     { question: "Who are we?", answer: "We are a group of researchers, engineers, and sign language enthusiasts dedicated to supporting the ASL and the Deaf & Hard of Hearing Community." },
//     { question: "Why did we make ASL-Live?", answer: "We wanted make an educational tool that anyone can use to learn ASL and/or communicate with others who use ASL." },
//     { question: "How does ASL-Live work?", answer: "ASL-Live uses machine learning to interpret sign language gestures and provide translations in real-time." },
//     { question: "Does ASL-Live cost money?", answer: "ASL-Live is free to use, unless Microsoft wants to ever buy us, then we will absolutely retire for life." },
//     { question: "Why do I need to provide video clips?", answer: "Although it is entirely optional, video clips help us improve our machine learning model and provide better translations. You can be part of something big." },
//     { question: "My camera isn't working. What should I do?", answer: "Please check your camera settings and make sure it is enabled for ASL-Live." },
//     { question: "How do I report a bug, request a feature, or provide feedback?", answer: "You can do all of these by contacting our support team down below!" },
//     { question: "How do I change my account settings?", answer: "You can't ;)" },
//     { question: "How do I reset my password?", answer: "We have not made that feature. Sorry not sorry bud." },
//     { question: "How do I delete my account?", answer: "You will forever be in our database lol." },
//     { question: "Will you sell my data?", answer: "We will never sell your data to third parties. Your privacy is important to us." },
//     { question: "I find this offensive. What should I do?", answer: "Please contact our support team with details about the issue." },
//     { question: "Will you ever make a battle pass? If so, how much would it be?", answer: "Maybe in the future idk... and it would def be $10." },
// ];

// export default function Support() {
//     const [activeIndex, setActiveIndex] = useState(null);

//     const handleToggle = (index) => {
//         setActiveIndex(activeIndex === index ? null : index);
//     };

//     return (
//         <div className="support-page">
//             <h1 className="support-title">Frequently Asked Questions</h1>
//             <h2>Reach out to our Support team for any inquiries</h2>
//             <div className="faq-container">
//                 {faqData.map((faq, index) => (
//                     <div key={index} className="faq-item">
//                         <button className="faq-question" onClick={() => handleToggle(index)}>
//                             {faq.question}
//                         </button>
//                         <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
//                             {faq.answer}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <p>If you would like to help us out by providing more datasets to make our product even better, please reach out to us. <br /><br />We have a straightforward reviewing process.</p>
//             <Contact />
//             <a href="https://github.com/NrgNinja/ASLive" target="_blank"><FaSquareGithub /></a>
//         </div>
//     );
// }

import React, { useState } from 'react';
import './Support.css';
import Contact from '../Contact';
import { FaGithub } from "react-icons/fa"; // Ensure correct import from 'react-icons/fa'

const faqData = [
    { question: "Who are we?", answer: "We are a group of researchers, engineers, and sign language enthusiasts dedicated to supporting the ASL and the Deaf & Hard of Hearing Community." },
    { question: "Why did we make ASL-Live?", answer: "We wanted make an educational tool that anyone can use to learn ASL and/or communicate with others who use ASL." },
    { question: "How does ASL-Live work?", answer: "ASL-Live uses machine learning to interpret sign language gestures and provide translations in real-time." },
    { question: "Does ASL-Live cost money?", answer: "ASL-Live is free to use, unless Microsoft wants to ever buy us, then we will absolutely retire for life." },
    { question: "Why do I need to provide video clips?", answer: "Although it is entirely optional, video clips help us improve our machine learning model and provide better translations. You can be part of something big." },
    { question: "My camera isn't working. What should I do?", answer: "Please check your camera settings and make sure it is enabled for ASL-Live." },
    { question: "How do I report a bug, request a feature, or provide feedback?", answer: "You can do all of these by contacting our support team down below!" },
    { question: "How do I change my account settings, reset my password, or delete my account", answer: "You can't ;)" },
    { question: "Will you sell my data?", answer: "We will never sell your data to third parties. Your privacy is important to us." },
    { question: "I find this offensive. What should I do?", answer: "Please contact our support team with details about the issue." },
    { question: "Will you ever make a battle pass? If so, how much would it be?", answer: "Maybe in the future idk... and it would def be $10." },
];

export default function Support()
{
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) =>
    {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="support-page">
            <h1 className="support-title">Frequently Asked Questions</h1>
            <h2>Reach out to our Support team for any inquiries</h2>
            <div className="support-layout">
                <div className="faq-container">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <button className="faq-question" onClick={() => handleToggle(index)}>
                                {faq.question}
                            </button>
                            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
                <Contact />
            </div>
            {/* <p>If you would like to help us out by providing more datasets to make our product even better, please reach out to us.</p>
            <div className="github-icon">
                <FaGithub size={48} onClick={() => window.open("https://github.com/NrgNinja/ASLive", "_blank")} />
            </div> */}
        </div>
    );
}