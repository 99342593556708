import React from 'react'
import { SupportButton } from './Button'
import { Link } from 'react-router-dom'
import './Footer.css';
import { TbHandTwoFingers } from "react-icons/tb";

function Footer()
{
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    "Welcome to another day in paradise!" ~Dr. Rick Leinecker
                </p>
                <p className='footer-subscription-text'>
                    College of Engineering & Computer Science, University of Central Florida
                </p>
                <div className='input-areas'>
                    <form>
                        <input
                            className='footer-input'
                            name='email'
                            type='email'
                            placeholder='Your Email'
                        />
                        <SupportButton buttonStyle='btn--outline'>Send A Message</SupportButton>
                    </form>
                </div>
            </section>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>About Us</h2>
                        <Link to='/more-info'>How it works</Link>
                        <Link to='/testimonials'>Testimonials</Link>
                        <Link to='/about'>Careers</Link>
                        <Link to='/about'>Investors</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Videos</h2>
                        <Link to='/demo'>Submit Video</Link>
                        <Link to='/about'>Ambassadors</Link>
                        <Link to='/extra-content'>Agency</Link>
                        <Link to='/extra-content'>Influencer</Link>
                    </div>
                </div>
            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                            ASL-Live
                            <TbHandTwoFingers className="navbar-icon"/>
                        </Link>
                    </div>
                    <small class='website-rights'>ASL-Live © 2024 All Rights Reserved</small>
                    <div class='social-icons'>
                        <Link
                            class='social-icon-link facebook'
                            to='/'
                            target='_blank'
                            aria-label='Facebook'
                        >
                            <i class='fab fa-facebook-f' />
                        </Link>
                        <Link
                            class='social-icon-link instagram'
                            to='/'
                            target='_blank'
                            aria-label='Instagram'
                        >
                            <i class='fab fa-instagram' />
                        </Link>
                        <Link
                            class='social-icon-link twitter'
                            to='/'
                            target='_blank'
                            aria-label='Twitter'
                        >
                            <i class='fab fa-twitter' />
                        </Link>
                        <Link
                            class='social-icon-link linkedin'
                            to='/'
                            target='_blank'
                            aria-label='LinkedIn'
                        >
                            <i class='fab fa-linkedin' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
