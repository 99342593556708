import React from 'react';
import './Contact.css';
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function Contact()
{
    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <form className="contact-form">
                <input type="text" placeholder="Your Name" required />
                <input type="email" placeholder="Your Email" required />
                <input placeholder="Your Message" required></input>
                <button type="submit">Send Message</button>
            </form>
            <p>If you would like to help us out by providing more datasets to make our product even better, please reach out to us.</p>
            <div className="contact-icons">
                <div className="github-icon">
                    <FaGithub size={48} onClick={() => window.open("https://github.com/NrgNinja/ASLive", "_blank")} />
                </div>
                <div className="linkedin-icon">
                    <FaLinkedin size={48} onClick={() => window.open("https://www.linkedin.com/in/renato-diaz/", "_blank")} />
                </div>
            </div>

        </div>
    );
}