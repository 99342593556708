import { DemoButton, InfoButton } from './Button'
import '../App.css';
import React from 'react'
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video-3.mp4' autoPlay loop muted />
      <h1>American Sign Language Translation Tool</h1>
      <p>Translate ASL to Text in Real Time</p>
      <h2>"Let's bridge the gap, not make it wider!"</h2>
      <div className='hero-btns'>
        <InfoButton className="btns" buttonStyle='btn--outline' buttonSize='btn--large'>
            More Info
        </InfoButton>
        <DemoButton className="btns" buttonStyle='btn--primary' buttonSize='btn--large'>
            Try It Out! <i className='far fa-play-circle'/>
        </DemoButton>
      </div>
    </div>
  )
}

export default HeroSection
