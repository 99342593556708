import React, { useState } from 'react'
import './Demo.css'
import WebcamComponent from '../Webcam'; // Import the Webcam component
import { CameraButton, SupportButton } from '../Button'; // Ensure CameraButton is also imported

export default function Demo() {
    const [isCameraActive, setCameraActive] = useState(false); // State to manage the webcam

    const toggleCamera = () => {
        setCameraActive(!isCameraActive); // Function to toggle the camera state
    };

    return (
        <div className="demo-page"> {/* Add a class for styling */}
            <h1 className="demo">ASL-Live in Action!</h1>
            <h2>Translate to your heart's desire</h2>
            <h3>Click "Turn Camera On" below to use our handy translation tool!</h3>
            <WebcamComponent isActive={isCameraActive} onUserMedia={() => console.log("Webcam started")} onUserMediaError={() => console.log("Webcam error")} />
            <CameraButton onClick={toggleCamera} buttonStyle="btn--outline" children={isCameraActive ? "Turn Camera Off" : "Turn Camera On"} />
            <SupportButton buttonStyle="btn--primary" buttonSize="btn--large">
                Go to Support
            </SupportButton>
            <p>Make sure to add a drop down menu of words that can be added for the user to record and/or upload. The user should be able to select a word and then we show them an example clip of how to sign that word. Figure this out bruv.</p>
        </div>
    );
}