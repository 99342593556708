import React from 'react'
import './About.css'
import '../Button.css'
import { LearnMoreButton } from '../Button';
import Authors from '../Authors';

export default function About()
{
    return (
        <div className="about-page">
            <img src="/images/group.jpg" alt="Group Picture" className="about-image" />
            <div className="about-content">
                <h1>Who Are We?</h1>
                <h2>Our Motivation</h2>
                <p>&emsp;We are a group of researchers, engineers, and sign language enthusiasts dedicated to supporting the ASL and the Deaf & Hard of Hearing Community. Our goal is to make an educational tool that anyone can use to learn ASL and/or communicate with others who use ASL.<br /><br />&emsp; Our story begins in the city of Orlando at the <strong>University of Central Florida</strong>, where by chance, a group of restless students formed to work on a new groundbreaking tool that could change the lives of not only the members of the ASL and Deaf Communities, but also anyone interest in learning more about this culture forever. What started off as a Computer Science Senior Design project at UCF, our tool has blossomed into so much more. Our goal is to be inclusive to everyone. We want to bridge the gap, not make it wider!</p>
                {/* <h4><br /><br />Include a link here to some charities and/or donate us some coffee or something idk.</h4> */}
                <LearnMoreButton buttonStyle="btn--primary" buttonSize="btn--large">
                    Learn More
                </LearnMoreButton>
            </div>
            {/* <Authors /> */}
        </div>
    );
}