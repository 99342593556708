import React from 'react'
import './ExtraContent.css'  // Make sure the path is correct
import { SupportButton } from '../Button';

export default function ExtraContent() {
    return (
        <div className="extra-content-container">
            <h1>Extra Content/Stuff To Add</h1>
            <h2>Idk what we're gonna put in this yet</h2>
            <h3>What other stuff might be useful???</h3>
            <iframe
                className="extra-content-video"
                src="https://www.youtube.com/embed/eU-P7eUctu0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
            <SupportButton buttonStyle="btn--primary" buttonSize="btn--large">
                Deez Nuts
            </SupportButton>
            <p>Renato wuz here :P</p>
        </div>
    );
}