import React from 'react';
import './Button.css'
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const LoginButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/sign-up' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const InfoButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/more-info' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const DemoButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/demo' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const SupportButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/support' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const CameraButton = ({ children, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}>
            {children}
        </button>
    );
};

export const LearnMoreButton = ({ children, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    const handleLearnMoreClick = () =>
    {
        window.open("https://www.lifeprint.com/", "_blank");
    };

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={handleLearnMoreClick}>
            {children}
        </button>
    );
};
