import React from 'react'
import Reviews from '../Reviews';
import './Testimonials.css'

function TestimonialsPage() {
    return (
        <div className='testimonials'>
            <h1>Testimonials</h1>
            <Reviews />
        </div>
    );
}

export default TestimonialsPage;