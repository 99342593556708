import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// this line imports bootstrap to use for styling
// !!! BE CAREFUL BC BOOTSTRAP FUCKS SOME SHIT UP
// import 'bootstrap/dist/css/bootstrap.min.css';

// add this, or similar to the js files that would be using the individual components
// import {Navbar, Nav, Button} from 'react-bootstrap';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// ReactDOM.render(<App/>, document.getElementById('root'));
