import React, { useEffect, useRef } from 'react';
import './Webcam.css';

const WebcamComponent = ({ isActive, onUserMedia, onUserMediaError, toggleCamera }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        async function enableStream() {
            if (isActive) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        if (onUserMedia) {
                            onUserMedia(stream);
                        }
                    }
                } catch (error) {
                    console.error('Error accessing the webcam:', error);
                    if (onUserMediaError) {
                        onUserMediaError(error);
                    }
                }
            } else {
                if (videoRef.current && videoRef.current.srcObject) {
                    videoRef.current.srcObject.getTracks().forEach(track => track.stop());
                    videoRef.current.srcObject = null;
                }
            }
        }

        enableStream();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
                videoRef.current.srcObject = null;
            }
        };
    }, [isActive, onUserMedia, onUserMediaError]);

    return (
        <div className="webcam-container">
            {isActive ? (
                <video ref={videoRef} autoPlay playsInline muted className="webcam-video">
                    Webcam stream not available.
                </video>
            ) : (
                <div className="webcam-inactive">
                    Your camera is off
                </div>
            )}
        </div>
    );
};

export default WebcamComponent;