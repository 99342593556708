import React from 'react'
import './MoreInfo.css'

export default function MoreInfo() {
    return (
        <div className="more-info-container">
            <h1>How Does It Work?</h1>
            <h2>Technical Details of ASL-Live</h2>
            <img src="/images/mlmodel.png" alt="Technical Blueprint of ASL-Live" className="more-info-image"/>
            <h3>In this diagram, we observe a high-level process of how our machine learning model for ASL-Live works.<br /><br /></h3>
            <p>(1) First, once a user has sent a video clip of their signs, OpenCV then separates the recording into 30 frames, where then MediaPipe creates landmarks on the frames and extracts points from the hands. <br /><br /> (2) Then, our algorithm separates each found ASL sign into an array of strings. <br /><br />(3) Finally, our model calls the OpenAI API to convert our "GLOSS" language to English text. That sentence is then sent back to the frontend so the user can view what was processed. This happens in real time.</p>
        </div>
    );
}